import React, { useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { motion } from "framer-motion";
import { supabase } from '../../components/Supabase/supabaseClient';

import "./Authenticate.css"

function Authenticate() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate(); 

    const handleLogin = async (event) => {
        event.preventDefault()
    
        setLoading(true)
        const { error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password,
          })
    
        if (error) {
            setMessage(error.error_description || error.message)
        } else {
            navigate('/account');
        }
        setLoading(false)
      }
    

    return (
        <motion.div 
            className="authenticate-container"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
                <div className="authenicate__container">
                    <h1>Login</h1>
                    <p>Use the form below to login to your ayo account.</p>
                    {message != '' &&
                        <Alert severity="error">{message}</Alert>
                    }
                    <div className="authenicate__form">
                        <form className="form-widget" onSubmit={handleLogin}>
                                <FormControl variant="standard">
                                    <TextField id="outlined-basic" label="Username" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                                    <TextField id="outlined-basic" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                                </FormControl>
                                <button className="button main" onClick={handleLogin}>Login</button>
                            </form>
                        </div>
                </div>
        </motion.div>
    )
}

export default Authenticate;