import React, { useState, useEffect  } from 'react';
import { useNavigate } from "react-router-dom";
import axios from '../Axios/axios';
import requests from '../../components/Requests/requests';
import ReactPlayer from 'react-player';
import  MediaLoader  from "../../components/MediaLoader/MediaLoader";
import './VideoPlayer.css';


function VideoPlayer({ id, mediaid, currentEpisode}) {
    const [movie, setMovie] = useState([]);
    const [media, setMedia] = useState([]);
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(0);
    const [showNextEpisode, setShowNextEpisode] = useState(false);
    const [nextEpisodeData, setNextEpisodeData] = useState(false);
    const [videoPlayed, setVideoPlayed] = useState(false);
    const [videoEndTime, setVideoEndTime] = useState(false);
    const [videoFinished, setVideoFinished] = useState(false);
    const [nextEpisode, setNextEpisode] = useState(false);
    useEffect(() => {
        async function fetchData() {
            const request = axios.get(requests.baseStreamRetrievalURL + id + requests.retrieveMp4Source + requests.putioApiKey.replace("?","&"))
              .then(function (response) {
                    if (response.data.parent.hasOwnProperty('mp4_stream_url')) {
                        setMovie(response.data.parent.mp4_stream_url);    
                    } else {
                        setMovie(response.data.parent.stream_url);
                    }
              })
              .catch(function (error) {
                console.log(error);
              });
            return request;
        }
        fetchData();
        setShowNextEpisode(false);
        openFullscreen();
    }, [id, currentEpisode]);
    function openFullscreen() {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }

    function handleProgress(e) {
      console.log(e);
      setVideoPlayed(e.playedSeconds);
      if (e.played > 0.99) {
        handleNextEpisode();
      }
      if (e.played === 1) {
        setVideoFinished(true);
      }
    }

    function handleDuration(duration) {
      setVideoEndTime(duration);
    }

    function handleNextEpisode() {
      const request = axios.get(requests.fetchIndividualShowById + mediaid)
        .then(function (response) {
          determineNextEpisode(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      return request;
    }

    function determineNextEpisode(movieData) {
      let season = parseInt(currentEpisode.split('S')[1].substring(0,2));
      let episode = parseInt(currentEpisode.split('E')[1].substring(0,2)) + 1;
      let episodes = movieData.episodes;
      let nextEpisodeData= episodes.find(episodes => episodes.season === season && episodes.episode === episode);
      if (!nextEpisodeData) {
        episode = 1;
        season = season + 1;
        nextEpisodeData= episodes.find(episodes => episodes.season === season && episodes.episode === episode);
      }
      if (nextEpisodeData) {
        setNextEpisodeData(nextEpisodeData);
        setShowNextEpisode(true);
        setNextEpisode('S' + ('0' + season).slice(-2) + 'E' + ('0' + episode).slice(-2))
      }
    }

    return ( 
        <>
            {(showNextEpisode && videoFinished) &&
              <MediaLoader key={1} processRequest={videoFinished} setProcesReuest={true} getWatchOrDownload={'watch'} id={mediaid} episode={nextEpisode} quality={localStorage.getItem("qualitySetting")} />
            }
            <ReactPlayer url={movie} playing={true} controls={true} onProgress={(e) => handleProgress(e)}  onDuration={(e) => handleDuration(e)} />
            {showNextEpisode &&
                <div className="player__nextvideo" onClick={handleNextEpisode}>
                    <p className="player__nextvideo__title">Next episode playing in {Math.round(videoEndTime - videoPlayed)}</p>
                    <p className="player__nextvideo__episode">{nextEpisodeData.title}</p>
                    <p className="player__nextvideo__episode__info">Season {nextEpisodeData.season} Episode {nextEpisodeData.episode}</p>
                </div>
            }
        </>
    )
}

export default VideoPlayer