import React, { useState, useEffect } from 'react';

import './../App.css';

import Home from './../pages/home/Home';
import Movies from './../pages/movies/Movies';
import Shows from './../pages/shows/Shows';
import Search from './../pages/search/Search';
import Info from './../pages/info/Info';
import Player from './../pages/player/Player';
import Authenticate from './../pages/authenticate/Authenticate';
import Profile from './../pages/account/Profile';
import Settings from './../pages/settings/Settings';
import Watchlist from './../pages/watchlist/Watchlist.js';
import Reportbug from './../pages/reportbug/Reportbug.js';
import Magnet from './../pages/magnet/Magnet.js';
import {BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navigation from './../components/Navigation/Navigation';

function AnimatedRoutes({key, session}) {
  const location = useLocation();
  return (
    <>
    <div className="App">
        {!(location.pathname.includes("/player/") || location.pathname.includes("/downloader/"))  &&
            <Navigation/>
        }
            <div className="content-body">
                <AnimatePresence mode="wait"
                    initial={false}
                    onExitComplete={() => window.scrollTo(0, 0)}>
                    <Routes location={location} key={location.pathname}>
                        <Route exact path="/" element={<Home/>} />
                        {!session ? <Route path="/account" /> : <Route path="/account" element={<Profile key={session.user.id} session={session}/>} />}
                        <Route path="/authenticate" element={<Authenticate/>} />
                        <Route path="/movies/:id/:page" element={<Movies/>} />
                        <Route path="/movies/:id" element={<Movies/>} />
                        <Route path="/movie/:id" element={<Info/>} />
                        <Route path="/movies" element={<Movies/>} />
                        <Route path="/shows/:id/:page" element={<Shows/>} />
                        <Route path="/shows/:id" element={<Shows/>} />
                        <Route path="/show/:id" element={<Info/>} />
                        <Route path="/shows" element={<Shows/>} />
                        <Route path="/search/:query" element={<Search/>} />
                        <Route path="/search/" element={<Search/>} />
                        <Route path="/player/:id" element={<Player/>} />
                        <Route path="/player/movie/:id/" element={<Player/>} />
                        <Route path="/player/show/:id/:mediaid/:episode" element={<Player/>} />
                        <Route path="/watchlist" element={<Watchlist/>} />
                        <Route path="/magnet" element={<Magnet/>} />
                        <Route path="/settings" element={<Settings/>} />
                        <Route path="/reportbug" element={<Reportbug/>} />
                    </Routes>
                </AnimatePresence>
            </div>
    </div>
        </>
  );
}

export default AnimatedRoutes;
