import React from 'react';
import './Spinner.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'transparent',
  boxShadow: 24,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const Spinner = ({loadingTitle,status, percentage, peers, isLoadingTransfer, open, handleClose, timeRemaining}) => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  if (!percentage) {
    percentage = 0;
  }
  if (!peers) {
    peers = 0;
  }
  return (
    <Modal
        open={open}
        onClose={handleClose}
    >
        <Box sx={style} className="loading-modal-container">
              <div className="lds-ring">
                <div></div><div></div><div></div><div></div>
              </div>
        <p>{loadingTitle}</p>
              {isLoadingTransfer &&
              <div className="status-text">
                <p>Status: {status}</p>
                
                {status == 'WAITING' && 
                <span> (storage may be full)</span>}
                {status == 'DOWNLOADING' &&
                  <><p>Time Remaining: {new Date(timeRemaining * 1000).toISOString().substring(11, 19)}</p>
                  <LinearProgressWithLabel  variant="determinate" value={percentage} />
                  </>
                }
              </div>
              }        
        </Box>
    </Modal>
  );
};

export default Spinner;