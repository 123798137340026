export const WatchHistory = (movie) => {
    let watchhistory = {};
    //Watch history doesn't exist yet so add first movie and return
    if (localStorage.getItem("watchHistory") === null) {
        watchhistory = {};
        watchhistory[movie._id] = movie;
        localStorage.setItem("watchHistory", JSON.stringify(watchhistory));
        window.dispatchEvent(new Event("storage")); //This is the important part
        return;
    }
    //Movie found in watch history so nothing to do
    if (localStorage.getItem("watchHistory").includes(movie._id)) {
        return;
    }
    //Watch history is not null but this film doesn't exist so add it
    watchhistory = JSON.parse(localStorage.getItem("watchHistory"));
    watchhistory[movie._id] = movie;
    localStorage.setItem("watchHistory", JSON.stringify(watchhistory));
    window.dispatchEvent(new Event("storage")); //This is the important part
    return;
}