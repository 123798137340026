import React, { useState, useEffect, useCallback} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddLinkIcon from '@mui/icons-material/AddLink';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import IosShareIcon from '@mui/icons-material/IosShare';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { WatchHistory } from "../../components/LocalStorage/WatchHistory";
import { WatchList } from "../../components/LocalStorage/WatchList";
import  MediaLoader  from "../../components/MediaLoader/MediaLoader";
import MessageManager from '../../components/MessageManager/MessageManager';
import HeaderNavigation from '../../components/HeaderNavigation/HeaderNavigation';
import Spinner from '../../components/Loader/./Spinner';
import TrailerModal from '../../components/TrailerModal/TrailerModal';


import './InfoPane.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`episode-tabs`}
        aria-labelledby={`episode-tabs`}
        {...other}
      >
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const mediaQualities = ['2160p', '1080p', '720p', '480p'];

const InfoPaneButton = ({index, children }) => {
    const onEnterPress = useCallback(() => {
      ref.current.children[0].click()
    }, []);
    const onFocus = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: 'center'
            }); 
        }

    }, []);
    const { ref, focused } = useFocusable({
      onEnterPress,
      onFocus,
      focusKey:'BANNER' + index
    });
    return (
        <div ref={ref} className={focused ? 'button-container focused' : 'button-container'} test={index}>
          {children}
        </div>
    );
}
const TabButton = (props) => {
    const onEnterPress = useCallback(() => {
      ref.current.children[0].click()
    }, []);
    const onFocus = useCallback(() => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: 'center'
        }); 
    }, []);
    const { ref, focused } = useFocusable({
      onEnterPress,
      onFocus,
      focusKey:"SEASONSELECT" + (props.indexTab + 1)
    });
    return (
        <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        ref={ref} 
        className={focused ? 'focused' : ''}
        {...props}
      />

    );
}

function InfoPane({ movie }) {
    const [loadingTitle, setLoadingTitle] = useState(true);
    const [error, setError] = useState(true);
    const [isQualityActive, setQualityActive] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [episode, setEpisode] = useState('');
    const [buttonClicked, setButtonClicked] = useState('');
    const [wishlistData, setWishlistData] = useState(localStorage.getItem('watchList'));
    const [wishlist, setWishlist] = useState(false);
    const [magnetIcon, setMagnetIcon] = useState(false);
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleOpenLoader = () => setOpenLoader(true);
    const [loadingMedia, setLoadingMedia] = useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseLoader = () => setOpenLoader(false);
    const handleChange = (event, newValue) => {
        setLoadingMedia(false);
        setTabValue(newValue);
    };
    
    const navigate = useNavigate(); 
    const location = useLocation();
    const { ref, setFocus, focusKey } = useFocusable();
    let isMovie = true;
    const vertical = 'bottom';
    const horizontal = 'center';
    if (location.pathname.includes('show')) {
        isMovie = false;
    }
    
    useEffect(() => {
        setError('');
        setLoadingTitle('Loading media data..');
        if (localStorage.getItem("qualitySetting") != null) {
            setQualityActive(localStorage.getItem('qualitySetting'));
        } else {
            if (isMovie) {
                setQualityActive(Object.keys(movie.torrents.en)[0]);
            } else {
                setQualityActive('1080p');
            }
        }
        
        if (wishlistData) {
            if(wishlistData.includes(movie._id)) {
                setWishlist(true);
            }
        }
        if (isMovie) {
            setFocus('BANNER1');
        } else {
            setFocus('SEASONSELECT1')
        }
    }, [movie]);

    const convertMinsToHrsMins = (minutes) => {
        var h = Math.floor(minutes / 60);
        var m = minutes % 60;
        h = h < 10 ? '' + h : h; 
        m = m < 10 ? '0' + m : m; 
        return h + 'hrs ' + m + 'mins';
    }

    const sortQualities = function (a, b) {
        return (Number(b.match(/(\d+)/g)[0]) - Number((a.match(/(\d+)/g)[0])));
    }

    const handleQualityClick = (event) => {
         setQualityActive(event.target.value);
         localStorage.setItem("qualitySetting", event.target.value);
    }

    const handleWatchOrDownload = (buttonClicked, showName, episodeClicked) => {
        setEpisode(showName);
        setButtonClicked(buttonClicked);

        if (isMovie) {
            if (movie.torrents.length == 0) {
                setError('Sorry, this movie is currently unavailable for streaming.');
                return;                
            }
            if (!Object.keys(movie.torrents.en).includes(isQualityActive)) {
                setError('This quality is unavailable. Please select another quality.');
                return;
            }
        } else {
            if (!Object.keys(movie.episodes[episodeClicked].torrents).includes(isQualityActive)) {
                setError('This quality is unavailable. Please select another quality.');
                return;         
            }
        }
        WatchHistory(movie);
        var mediaUrl = (buttonClicked == 'download') ? '/downloader/' :'/player/';
        mediaUrl = (isMovie) ? mediaUrl + 'movie/' + movie._id : mediaUrl + 'show/' + movie._id + '/' + showName;
        mediaUrl = mediaUrl + '/' + isQualityActive;
        setLoadingMedia(true);
    }
    const handleMagnetCopy = (episode) => {
        try {
            if (isMovie) {
                navigator.clipboard.writeText(movie.torrents.en[isQualityActive].url);
            } else {
                navigator.clipboard.writeText(episode[isQualityActive].url);
            }
            setMagnetIcon(true);
          } catch (err) {
            setMagnetIcon(false);
          }
    }
    if (error) {
        return (
            <MessageManager showMessage={true} type={'error'}>
                {error}
            </MessageManager> 
        )    
    } else {
    return (
    <>
    <Helmet>
        <style>
            {'.banner-background { background-image: url(' + movie?.images?.fanart.replace('w500', 'w1920_and_h800_multi_faces').replace("http", "https") +'); }'}
    </style>
    </Helmet>
    <Spinner loadingTitle={loadingTitle} open={openLoader} handleClose={handleCloseLoader} />
    <MediaLoader key={isQualityActive + episode} processRequest={loadingMedia} setProcesReuest={setLoadingMedia} getWatchOrDownload={buttonClicked} id={movie._id} episode={episode} quality={isQualityActive} />
    <div className="banner-container">
    <HeaderNavigation showMovieStack={false} />
        <div className="infopane__container">
            {movie?.trailer != undefined  &&
                <TrailerModal open={open} handleClose={handleClose} trailer={movie?.trailer} />
            }
            <div className="infopane__content__header">
                <div className="infopane__content__left">
                    <div className="infopane__poster">
                        <img
                            src={movie?.images?.poster.replace("http", "https")}
                            alt={movie?.title} />
                            {!wishlist ? (
                                                <InfoPaneButton key={5} index={5}>
                                                    <Tooltip title="Add to watchlist">
                                                        <button className="button clear" onClick={function(event){  WatchList(movie, "add"); setWishlist(true);}}>
                                                                <FavoriteBorderIcon/> <span className='button-text'>Watchlist</span>
                                                        </button>  
                                                    </Tooltip> 
                                                </InfoPaneButton>                 
                                            ) : (
                                                <InfoPaneButton key={6} index={6}>
                                                    <Tooltip title="Remove from watchlist">
                                                        <button className="button clear" onClick={function(event){  WatchList(movie, "remove"); setWishlist(false);}}>
                                                                <FavoriteIcon/> <span className='button-text'>Watchlist</span>
                                                        </button>
                                                    </Tooltip>
                                                </InfoPaneButton>                              
                            )}
                    </div>
                    <div className="infopane__info">
                        <div className="infopane__title">
                            <h1>{movie?.title}</h1>
                        </div>
                        <div className="infopane__meta__container">
                            <ul className="infopane__meta mobile-left">
                                <li><span className="infopane__year">{movie.year}</span></li>
                                {(movie.runtime > 0 && movie.num_seasons === undefined) &&
                                    <li><span className="infopane__length">{convertMinsToHrsMins(movie?.runtime)}</span></li>
                                }
                                {!isMovie &&
                                    <li><span className="infopane__length">{movie.num_seasons} Seasons</span></li>
                                }
                                <li>
                                    <div className="banner__rating">
                                        <Rating name="read-only" value={(movie?.rating?.percentage/10)/2} readOnly />
                                    </div>
                                </li>
                                {movie?.torrents?.en &&
                                    <>
                                        {movie?.torrents?.en['2160p'] != undefined &&
                                            <li>
                                                <span className="label">UHD</span>
                                            </li>
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="infopane__description">{movie?.synopsis}</div>
            {isMovie &&
            <div className="infopane__quality">
                <FormControl sx={{ m: 1, minWidth: 80 }}>
                     <Select
                        labelId="quality-select"
                        id="quality-select-dropdown"
                        value={isQualityActive}
                        label="Select Quality"
                        onChange={handleQualityClick}
                    >
                    {movie?.torrents?.en ? mediaQualities.map((value, index) => (
                        <MenuItem key={'q' + value} value={value} disabled={Object.keys(movie.torrents.en).includes(value) ? false : true}>{value.replace('2160p','4K')} {Object.keys(movie.torrents.en).includes(value) ? '(' + movie.torrents.en[value].filesize + ')' : '(unavailable)'}</MenuItem>
                    )): null}  
                    </Select>
                </FormControl>
            </div>
            }
             <div className='movie-buttons-container'>
                    <div className="movie-buttons">
                        {!isMovie &&
                            <InfoPaneButton key={7} index={7}>
                                <a href="#episode-list" className="button main">
                                    <PlayArrowIcon/> Watch Now
                                </a>
                            </InfoPaneButton>
                        }
                        {isMovie &&
                            <>
                            <InfoPaneButton key={8} index={8}>
                                <button className="button main" onClick={() => handleWatchOrDownload('watch')}>
                                    <PlayArrowIcon/> Watch Now
                                </button>
                             </InfoPaneButton>
                             <InfoPaneButton key={9} index={9}>
                                <button className="button" onClick={() => handleWatchOrDownload('download')}>
                                    <DownloadIcon/> Download
                                </button>
                            </InfoPaneButton>
                            <div className='secondary-buttons'>
                                <InfoPaneButton key={10} index={10}>
                                    <Tooltip title="Watch trailer">
                                        <button className="button clear" onClick={handleOpen}>
                                            <SlideshowIcon/> <span className='button-text'>Trailer</span>
                                        </button>
                                    </Tooltip> 
                                </InfoPaneButton>
                                {!magnetIcon ? (
                                    <InfoPaneButton key={11} index={11}>
                                        <Tooltip title="Copy magnet link">
                                            <button className="button clear" onClick={handleMagnetCopy}>
                                                <AddLinkIcon/> <span className='button-text'>Magnet</span>
                                            </button>
                                        </Tooltip> 
                                    </InfoPaneButton>
                                ) : (
                                    <InfoPaneButton key={12} index={12}>
                                        <Tooltip title="Copy magnet link">
                                            <button className="button clear" onClick={handleMagnetCopy}>
                                                <DoneIcon/> <span className='button-text'>Magnet</span>
                                            </button>
                                        </Tooltip> 
                                    </InfoPaneButton>
                                )}
                                    <InfoPaneButton key={13} index={13}>
                                        <Tooltip title="Share">
                                            <button className="button clear" onClick={function(event){ }}>
                                                <IosShareIcon/> <span className='button-text'>Share</span>
                                            </button>
                                        </Tooltip>
                                    </InfoPaneButton>   
                                </div>
                                </>
                            }
                    </div>
                </div>
                </div>
            </div>
                <div className='infopane__further__info'>
                    <h2>Information</h2><br></br>
                    {isMovie && 
                        <div className='infopane__further__info__content'>
                            <strong>MATURITY RATING:</strong> 
                            <p>{movie?.certification}</p>
                        </div>
                    }
                    {!isMovie && 
                        <div className='infopane__further__info__content'>
                            <strong>Network:</strong> 
                            <p>{movie?.network}</p>
                        </div>
                    }
                    {!isMovie && 
                        <div className='infopane__further__info__content'>
                            <strong>Status:</strong> 
                            <p>{movie?.status}</p>
                        </div>
                    }
                    <div className='infopane__further__info__content'>
                        <strong>Genres:</strong> 
                        <p>{movie?.genres?.map((value, index) => (
                                <span key={'genre' + index}>{index !== 0 && (index === movie?.genres - 1 ? ", and" : ",")} {value}</span>
                        ))}</p>
                    </div>
                    <div className='infopane__further__info__content'>
                        <strong>TRANSLATIONS:</strong>
                        <p>{movie?.exist_translations?.map((value, index) => (
                            <span key={'translation'  + index}>{index !== 0 && (index === movie?.exist_translations - 1 ? ", and" : ",")} {value}</span>
                        ))}</p>
                    </div>
                    <div className='infopane__further__info__content'>
                        <strong>RATINGS VOTES:</strong>
                        <p>{movie?.rating?.votes}</p>
                    </div>
                    {isMovie && 
                        <div className='infopane__further__info__content'> 
                            <strong>TORRENT PROVIDERS: </strong>
                            <p>{movie?.torrents?.en ? Object.keys(movie.torrents.en).sort(sortQualities).map((value, index) => (
                                <span key={'providers'+ index}>{index !== 0 && (index === movie.torrents.en.length - 1 ? ", and" : ",")} {movie.torrents.en[value].provider}</span>
                            )): null} </p>
                        </div> 
                    }
                </div>
            </div>
            </div>
            
            </div>
            {!isMovie &&
            <div className="infopane__episodes__container">
                <div className="infopane__content">
                        <div className="infopane__episodes">
                            <div className="infopane__episodes_list" id="episode-list">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="Episode Tabs">
                                    {Array(movie.num_seasons).fill(null).map((value, index) => (
                                        <TabButton key={'tab' + index} label={'Season ' + (index + 1)} indexTab={index}/>
                                    ))}   
                                </Tabs>     
                            </Box>
                            {Array(movie.num_seasons).fill(null).map((value, index) => (
                                        <TabPanel value={tabValue} index={index}>
                                        {movie?.episodes ? Object.keys(movie?.episodes?.sort(function(a,b){
                                            if(a.episode < b.episode) return -1;
                                            if(a.episode > b.episode) return 1;
                                            return 0;
                                        })).map((values, i) => (
                                                movie.episodes[values].season == index + 1 &&
                                                        <div className="infopane__episodes_list_epsiode">
                                                        <div className='magnet-link'>
                                                            <InfoPaneButton key={'magnet' + movie.episodes[values].season + movie.episodes[values].episode} index={'magnet' + movie.episodes[values].season + movie.episodes[values].episode}>
                                                                <Tooltip title="Copy magnet link">
                                                                    <button className="button clear" onClick={() => handleMagnetCopy(movie.episodes[values].torrents)} >
                                                                        <AddLinkIcon/> <span className='button-text'>Magnet</span>
                                                                    </button>
                                                                </Tooltip> 
                                                            </InfoPaneButton>
                                                        </div>
                                                        <h6>S{movie.episodes[values].season}E{movie.episodes[values].episode}</h6>
                                                        <h2> {movie.episodes[values].title}</h2>
                                                        <p>{movie.episodes[values].overview}</p>
                                                        <div className="infopane__quality">
                                                            <FormControl sx={{ m: 1, minWidth: 80 }}>
                                                                <Select
                                                                    labelId="quality-select"
                                                                    id="quality-select-dropdown"
                                                                    value={isQualityActive}
                                                                    label="Select Quality"
                                                                    onChange={handleQualityClick}
                                                                >
                                                                {movie.episodes[values].torrents ? mediaQualities.map((value, index) => (
                                                                    <MenuItem value={value} disabled={Object.keys(movie.episodes[values].torrents).includes(value) ? false : true}>{value.replace('2160p','4K')} {Object.keys(movie.episodes[values].torrents).includes(value) ? '' : '(unavailable)'}</MenuItem>
                                                                )): null}  
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                            <div className="infopane__download__buttons">
                                                                <InfoPaneButton key={'watch' + movie.episodes[values].season + movie.episodes[values].episode} index={'watch' + movie.episodes[values].season + movie.episodes[values].episode}>
                                                                    <button className="button main" onClick={() => handleWatchOrDownload('watch', 'S' + ('0' + movie.episodes[values].season).slice(-2) + 'E' + ('0' + movie.episodes[values].episode).slice(-2), values)}><PlayArrowIcon/> Watch Now</button>
                                                                </InfoPaneButton>
                                                                <InfoPaneButton key={'download' + movie.episodes[values].season + movie.episodes[values].episode} index={'download' + movie.episodes[values].season + movie.episodes[values].episode}>
                                                                    <button className="button" onClick={() => handleWatchOrDownload('download', 'S' + ('0' + movie.episodes[values].season).slice(-2) + 'E' + ('0' + movie.episodes[values].episode).slice(-2), values)}><DownloadIcon/> Download</button>
                                                                </InfoPaneButton>
                                                            </div>
                                                        </div>
                                                    
                                        )): null} 
                                    </TabPanel> 
                            ))}                            
                            </div>
                        </div>
                </div>
            </div>
            }
        </>
    )
    }
}
export default InfoPane;