import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import MovieIcon from '@mui/icons-material/Movie';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SettingsIcon from '@mui/icons-material/Settings';
import TvIcon from '@mui/icons-material/Tv';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FlagIcon from '@mui/icons-material/Flag';
import Avatar from '@mui/material/Avatar';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import './Navigation.css';
import Chip from '@mui/material/Chip';

const MenuItem = ({ children }) => {
  const onEnterPress = useCallback(() => {
    ref.current.children[0].click()
  }, []);
  const { ref, focused } = useFocusable({
    onEnterPress
  });
  return (
    <li ref={ref} className={focused ? 'menu-item focused' : 'menu-item'}>
      {children}
    </li>
  );
}

function Navigation() {
    const { ref, focusKey, hasFocusedChild  } = useFocusable({ focusKey: 'MENU', trackChildren: true});
    const [watchListCount, setWatchListCount] = useState('');
    let pathname = window.location.pathname;
    useEffect(() => {
        function checkWatchList() {
            let watchlist = JSON.parse(localStorage.getItem("watchList"));
            setWatchListCount(watchlist !== null ? Object.keys(watchlist).length : 0);
          }
          checkWatchList();
        window.addEventListener("storage", checkWatchList);
        pathname = window.location.pathname;
        return () => {
            window.removeEventListener('storage', checkWatchList);
        }
    }, []);

    return (
        <>
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={hasFocusedChild ? 'nav expanded' : 'nav'}>
                <div className="nav__menu__container">
                    <a href="/" className='logo-container'><h1 className="logo">ayo<span className="logo-blue"></span></h1></a>
                    <div className="menu">
                        <ul className="nav__menu">
                            <span className="nav__menu__tile">Menu</span>
                            <MenuItem key={1}>
                                <NavLink className={({ isActive }) => isActive || window.location.href.indexOf("/search") > -1? "active": ''} to='/search'>
                                    <SearchIcon/> <span>Search</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key={2}>
                                <NavLink className={({ isActive }) => isActive || window.location.href.indexOf("/movie") > -1? "active": ''} to='/'>
                                    <MovieIcon/> <span>Movies</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key={3}>
                                <NavLink className={({ isActive }) => isActive? "active": ''} to='/watchlist'>
                                    <FavoriteIcon/> <span>Watchlist</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key={4}>
                                <NavLink className={({ isActive }) => isActive || window.location.href.indexOf("/show") > -1 ? "active": ''} to='/shows/trending/'>
                                    <TvIcon/>  <span>Shows</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key={5}>
                                <NavLink className={({ isActive }) => isActive ? "active": ''} to='/magnet'>
                                    <AddIcon/> <span>Add Magnet</span>
                                </NavLink>
                            </MenuItem>
                            <MenuItem key={6}>
                                <NavLink className={({ isActive }) => isActive? "active": ''} to='/settings'>
                                    <SettingsIcon/> <span>Settings</span>
                                </NavLink>
                            </MenuItem>
                        </ul>
                    </div>
                    <div className="menu">
                        <ul className="nav__menu">
                            <MenuItem key={8}>
                                <NavLink className={({ isActive }) => isActive? "active": ''} to='/authenticate'>
                                    <Avatar>G</Avatar>
                                </NavLink>
                            </MenuItem>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobileNav">
                <div className='mobileNav__button'>
                    <NavLink className={({ isActive }) => isActive || window.location.href.indexOf("/movie/") > -1? "active": ''} to='/movies/trending/'>
                        <IconButton>
                            <MovieIcon/>
                        </IconButton>
                        <p>Movies</p>
                    </NavLink>
                </div>
                <div className='mobileNav__button'>
                    <NavLink className={({ isActive }) => isActive || window.location.href.indexOf("/show/") > -1 ? "active": ''} to='/shows/trending/'>
                        <IconButton>
                            <TvIcon/>
                        </IconButton>
                        <p>TV Shows</p>
                    </NavLink>
                </div>
                <div className='mobileNav__button mobile-search'>
                    <NavLink className={({ isActive }) => isActive? "active": ''} to='/search'>
                        <SearchIcon>
                            <FlagIcon/>
                        </SearchIcon>
                        <p>Search</p>
                    </NavLink>
                </div>
                <div className='mobileNav__button'>
                    <NavLink className={({ isActive }) => isActive? "active": ''} to='/magnet'>
                        <IconButton>
                            <AddIcon/>
                        </IconButton>
                        <p>Magnet</p>
                    </NavLink>
                </div>
                <div className='mobileNav__button user'>
                    <NavLink className={({ isActive }) => isActive? "active": ''} to='/settings'>
                        <IconButton>
                            <Avatar>S</Avatar>
                        </IconButton>
                        <p>Settings</p>
                    </NavLink>
                </div>
            </div>
        </FocusContext.Provider>
        </>
    )
}

export default Navigation