import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../components/Axios/axios';
import requests from '../../components/Requests/requests';
import Banner from '../../components/Banner/Banner';
import LoadingBanner from '../../components/Banner/LoadingBanner';
import Row from '../../components/Row/Row';
import LoadingRow from '../../components/Row/LoadingRow';
import MessageManager from '../../components/MessageManager/MessageManager';
import Pagination from '../../components/Pagination/Pagination';
import HeaderNavigation from '../../components/HeaderNavigation/HeaderNavigation';
import { motion } from "framer-motion";

function Shows() {
    let { id, page } = useParams();
    const [apiResponse, setApiResponse] = useState([]);
    const [error, setError] = useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const handleOpenLoader = () => setOpenLoader(true);
    const handleCloseLoader = () => setOpenLoader(false);
    page = (!page) ? 1 : page;

    useEffect(() => {
        handleOpenLoader();
        let url;
        async function fetchData() {
            if (!id || id == 'trending') {
                url = requests.fetchShows + page + '?sort=trending&order=-1&keywords=&limit=18'
            } else {
                url = requests.fetchShows + page + '?genre=' + id + '&order=-1&keywords=&limit=18';
            }
            const request = await axios.get(url)
            .then(request => {
                setApiResponse(request.data);
            })
            .catch(function (error) {
                setError('Sorry, we were unable to load show data via the API. Try updating your API server settings.');
            });
            handleCloseLoader();
            return request;
        }
        fetchData();
    }, [id]);
    if (error) {
        return (
            <motion.div 
                className="show-container"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
            <MessageManager showMessage={true} type={'error'}>
                {error}
            </MessageManager>
            </motion.div>   
        )    
    } else {
        return (
            <>
            <motion.div 
                    className="show-container"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <div className="banner-container">
                        <HeaderNavigation showTvStack={true} />
                        {page == 1 &&
                            <Banner data={apiResponse} movieNo={0} showBanner={!openLoader} />
                        }
                        {openLoader && 
                            <LoadingBanner/>
                        }
                    </div>
                    <div className="inner-content-body">
                        <Row title={id} data={apiResponse} isLargeRow />
                        {openLoader && 
                            <LoadingRow/>
                        }
                        <Pagination pageNumber={page} />
                        <MessageManager/>
                    </div>
                </motion.div>
            </>
        )
    }
}

export default Shows;