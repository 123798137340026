import React, { useEffect, useCallback } from 'react';
import { NavLink,useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';

const CategoryStackItem = ({ index, children }) => {
  const onEnterPress = useCallback(() => {
    ref.current.children[0].click()
  }, []);
  const onFocus = useCallback(() => {
      ref.current.scrollIntoView({
          behavior: "smooth",
          block: 'center'
      }); 
  }, []);
  const { ref, focused } = useFocusable({
    onEnterPress,
    onFocus,
    focusKey:'CATEGORYSTACK' + index
  });
  return (
      <div ref={ref} className={focused ? 'headerNavigation__categories__item focused' : 'headerNavigation__categories__item'}>
          {children}
      </div>
  );
}
function CategoryStack({showMovieStack, showTvStack, showSearchStack}) {
  const { ref, focusKey } = useFocusable({focusKey: 'CATEGORYSTACK'});
  const { search } = useLocation();

  if (showMovieStack == true) {
    return (
      <div className="headerNavigation__categories">
          <Stack direction="row" spacing={1} style={{maxHeight: '100%', overflow: 'auto'}} >
              <CategoryStackItem key={1} index={1}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/'}><Chip label="🍿 Trending" /></NavLink>
                
              </CategoryStackItem>
              <CategoryStackItem key={2} index={2}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/watchlist'}><Chip label="❤ Watchlist" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={3} index={3}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/action'}><Chip label="🔫 Action" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={4} index={4}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/documentary'}><Chip label="⏱ Documentary" /></NavLink>
              </CategoryStackItem>
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/drama'}><Chip label="🔍 Drama" /></NavLink>*/}
              <CategoryStackItem key={5} index={5}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/family'}><Chip label="👪 Family" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={6} index={6}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/fantasy'}><Chip label="🦄 Fantasy" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={7} index={7}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/horror'}><Chip label="👻 Horror" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={8} index={8}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/crime'}><Chip label="🚓 Crime" /></NavLink>
              </CategoryStackItem>
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/thriller'}><Chip label="🔪 Thriller" /></NavLink>
              <NavLink className={({ isActive }) => isActive? "active": ''} to={'/movies/western'}><Chip label="🤠 Western" /></NavLink>*/}
          </Stack>
        
      </div>
    );
  } else if (showTvStack == true) {
    return (
      <div className="headerNavigation__categories">
        <FocusContext.Provider value={focusKey}>
          <Stack direction="row" spacing={1} style={{maxHeight: '100%', overflow: 'auto'}} >
              <CategoryStackItem key={1} index={1}>
              <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/trending'}><Chip label="🍿 Trending" /></NavLink>
              </CategoryStackItem>
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/action'}><Chip label="🔫 Action" /></NavLink>*/}
              <CategoryStackItem key={2} index={2}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/comedy'}><Chip label="😂 Comedy" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={3} index={3}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/crime'}><Chip label="🚓 Crime" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={4} index={4}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/documentary'}><Chip label="⏱ Documentary" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={5} index={5}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/drama'}><Chip label="🔍 Drama" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={6} index={6}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/family'}><Chip label="👪 Family" /></NavLink>
              </CategoryStackItem>
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/fantasy'}><Chip label="🦄 Fantasy" /></NavLink>*/}
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/horror'}><Chip label="👻 Horror" /></NavLink>*/}
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/romance'}><Chip label="❤ Romance" /></NavLink>*/}
              {/*<NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/thriller'}><Chip label="🔪 Thriller" /></NavLink>*/}
              <CategoryStackItem key={7} index={7}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={'/shows/western'}><Chip label="🤠 Western" /></NavLink>
              </CategoryStackItem>
          </Stack>
        </FocusContext.Provider>
      </div>
    );
  } else if (showSearchStack == true) {
    return (
      <div className="headerNavigation__categories">
        <FocusContext.Provider value={focusKey}>
          <Stack direction="row" spacing={1} style={{maxHeight: '100%', overflow: 'auto'}} >
              <CategoryStackItem key={1} index={1}>
              <NavLink className={({ isActive }) => isActive? "active": ''} to={`/search/movies${search}`}><Chip label="Movie results" /></NavLink>
              </CategoryStackItem>
              <CategoryStackItem key={2} index={2}>
                <NavLink className={({ isActive }) => isActive? "active": ''} to={`/search/shows${search}`}><Chip label="Show results" /></NavLink>
              </CategoryStackItem>
          </Stack>
        </FocusContext.Provider>
      </div>
    );  
  }
};

export default CategoryStack;