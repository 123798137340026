import React, { useState, useEffect } from 'react';
import AnimatedRoutes from './utils/AnimatedRoutes';
import {BrowserRouter as Router, Routes, Route, Link, useParams, useLoction } from "react-router-dom";
import { useFocusable, init, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import './App.css';

function App() {

  //Spartial Nav
  const MENU_FOCUS_KEY = 'BANNER1';
  const { ref, setFocus, focusKey } = useFocusable();

  const [session, setSession] = useState(null)

  useEffect(() => {
    //supabase.auth.getSession().then(({ data: { session } }) => {
      //setSession(session)
    //})

    //supabase.auth.onAuthStateChange((_event, session) => {
      //setSession(session)
    //})
  }, [])

  init({
    visualDebug:false
  });

  document.title = 'Ayo';

  return (
    <FocusContext.Provider value={focusKey}>
      <div className="dark-mode" ref={ref}>
        <Router>
            {!session ? <AnimatedRoutes /> : <AnimatedRoutes key={session.user.id} session={session} /> }
        </Router>
      </div>
    </FocusContext.Provider>
  );
}

export default App;
