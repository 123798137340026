import React, { useState, useEffect, useRef} from 'react';
import { motion } from "framer-motion";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import MessageManager from '../../components/MessageManager/MessageManager';
import axios from '../../components/Axios/axios';
import requests from '../../components/Requests/requests';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Downloader from '../../components/Downloader/Downloader';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`} ({props.time})</Typography>
        </Box>
      </Box>
    );
  }
  
function MediaLoader({processRequest,setProcesReuest, id, getWatchOrDownload, episode, quality, magnet}) {
    let fetchUrl = requests.fetchIndividualMovieById + id;
    let isMovie = true;
    const [movie, setMovie] = useState([]);
    const [error, setError] = useState('');
    //const [showMessage, setShowMessage] = useState(processRequest);
    const [transferPercent, setTransferPercent] = useState(true);
    const [transferState, setTransferState] = useState(true);
    const [transferEstimate, setTransferEstimate] = useState(true);
    const [isLoadingTransfer, setisLoadingTransfer] = useState(true);
    const [loadingTitle, setLoadingTitle] = useState(true);
    const [fileid, setFileId] = useState();
    const [nextEpisode, setNextEpisode] = useState(true);
    const location = useLocation();
    const timer = useRef(null); // we can save timer in useRef and pass it to child
    const navigate = useNavigate(); 

    const loadMediaData = (movie) => {
            if (isMovie) {
                if (quality == undefined) {
                    magnet = Object.values(movie.torrents.en)[0].url;
                } else {
                    magnet = movie.torrents.en[quality].url;
                }
            } else {
                var selectedSeason, selectedEpisode;
                selectedSeason = episode.split('E')[0].replace('S', '');
                selectedEpisode = episode.split('E')[1];
                for (const key in movie.episodes) {
                    if (movie.episodes[key].season == selectedSeason) {
                        if (movie.episodes[key].episode == selectedEpisode) {
                            if (quality == undefined) {
                                magnet = movie.episodes[key].torrents[0].url;
                            } else {
                                magnet = movie.episodes[key].torrents[quality].url;  
                            }
                        }    
                    }
                }
            }
        async function fetchData() {
            const request = axios.post(requests.addMagnetToPutio + requests.putioApiKey, {
                url: magnet,
                save_parent_id: requests.putioFolderId
              })
              .then(function (response) {
                processTransfer(response.data.transfer.id);
              })
              .catch(function (error) {
                if (error.response.data.error_type == 'TRANSFER_ALREADY_ADDED') {
                    processTransfer(error.response.data.extra.existing_id);    
                    setTransferState('Transfer already added')
                } else {
                    setTransferState('Failed to retrieve media information. Put.io is likely full.');
                }
              });
            return request;
        }
        fetchData();
    }

    const processTransfer = (transferId) => {
        async function fetchData() {
            const request = axios.get(requests.checkTransferWithPutio + transferId + requests.putioApiKey)
              .then(function (response) {
                if (response.data.transfer.status == 'COMPLETED' || response.data.transfer.status == 'SEEDING' ) {
                    setTransferState('Completed');
                    retrieveFileId(response.data.transfer.file_id)
                } else {
                    setisLoadingTransfer(true);
                    setLoadingTitle('Loading media..');
                    setTransferPercent(response.data.transfer.percent_done);
                    setTransferState(response.data.transfer.status);
                    setTransferEstimate(response.data.transfer.estimated_time);
                    timer.current = setTimeout(function() { //Start the timer
                        processTransfer(transferId);
                    }.bind(this), 5000)
                }
              })
              .catch(function (error) {
                setTransferState('error');
              });
            return request;
        }
        fetchData();
    }

    const retrieveFileId = (newFileId) => {
        async function fetchData() {
            const request = axios.get(requests.baseStreamRetrievalURL + newFileId + requests.retrieveMp4Source + requests.putioApiKey.replace("?","&"))
              .then(function (response) {
                //Movie or show is direct file so immediately return
                if (response.data.parent.hasOwnProperty('stream_url')) {
                    handleTransition(response.data.parent.id)
                    return request;
                }
                //Folder found so loop through files within folder
                Object.keys(response.data.files).map((value, index) => {
                    //If it's a show select the file which matches show name
                    if (response.data.parent.file_type == 'FOLDER' && !isMovie) {
                        if (response.data.files[value].name.toLowerCase().includes(episode.toLowerCase())) {
                            if (response.data.files[value].content_type == 'video/mp4' || response.data.files[value].content_type == "video/x-matroska") {
                                handleTransition(response.data.files[value].id);
                            }
                        }
                    } else {
                        //It's not a show so just find the mp4 within the folder
                        if (response.data.files[value].content_type == 'video/mp4' || response.data.files[value].content_type == "video/x-matroska") {
                            handleTransition(response.data.files[value].id);
                        }
                    }
                });
              })
              .catch(function (error) {
                setError('Error.')
                return('');
              });
              function handleTransition(fileid) {
                let mediaUrl;
                if (getWatchOrDownload == 'watch') {
                    mediaUrl = (isMovie) ? '/player/movie/' + fileid : '/player/show/' + fileid + '/' + id + '/' + episode;
                    navigate(mediaUrl);
                } else {
                    setFileId(fileid);
                }
              }
            return request;
        }
        fetchData();
    }

    useEffect(() => {
        async function fetchData() {
            if (window.location.pathname.includes('show')) {
                if (episode == undefined) {
                    setError('Episode data is missing from the request.')
                    return('');
                }
                fetchUrl = requests.fetchIndividualShowById + id;
                isMovie = false;
            }
            const request = await axios.get(fetchUrl)
              .then(function (response) {
                setMovie(response.data);
                loadMediaData(response.data);
              }).catch(function (error) {
                setTransferState('Sorry, we were unable to retrieve the required media data for ID: ' + id);
              });
            return request;
        }
        if (processRequest) {
            fetchData();
        } else {
            clearInterval(timer.current);
        }
    }, [processRequest]);
    if (!processRequest) {
        return;
    } else {
        return (
            <MessageManager showMessage={processRequest} setShowMessage={setProcesReuest} type={!error == '' ? ('error') : fileid ? ('success') : 'info'}>
                {!error == '' ? (
                    <>
                        <p><strong>Error</strong></p>
                        <p>{error}</p>
                    </>
                ) : 
                fileid ? (
                    <Downloader id={fileid}/>
                ) : (
                    <>
                        <strong>{loadingTitle}</strong>
                        {isLoadingTransfer &&
                            <div className="status-text">
                                Status: {transferState}
                                {transferState == 'WAITING' && 
                                    <span> - Our queue is currently full.</span>}
                                {transferState == 'DOWNLOADING' &&
                                    <>
                                        <LinearProgressWithLabel value={transferPercent + ' '} time={new Date(transferEstimate * 1000).toISOString().substring(11, 19)}/>  
                                    </>
                                }
                            </div>
                        }
                    </>
                )}
            </MessageManager>
        )
    }
}

export default MediaLoader;