import React, { useState, useEffect} from 'react';
import { motion } from "framer-motion";
import { useParams, useLocation } from 'react-router-dom';
import VideoPlayer from '../../components/Player/VideoPlayer';

function Player() {
    let { id, mediaid, episode } = useParams();

    if (!id) {
        return (
            <p>error</p>
        )
    } else {
        return (
            <motion.div 
                className=""
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                {id != undefined &&
                    <VideoPlayer id={id} mediaid={mediaid} currentEpisode={episode}/>
                }
            </motion.div>
        )
    }
}

export default Player;