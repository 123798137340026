const requests = {
    fetchMovies: '/movies/',
    fetchSearch: '/movies/1?keywords=',
    fetchIndividualMovieById: '/movie/',

    fetchShows: '/shows/',
    fetchShowSearch: '/shows/1?keywords=',
    fetchIndividualShowById: '/show/',

    putioFolderId: '749881982',
    putioApiKey: '?oauth_token=RWAQONPCEOHKRYNPAOB6',
    addMagnetToPutio: 'https://api.put.io/v2/transfers/add',
    checkTransferWithPutio: 'https://api.put.io/v2/transfers/',
    baseStreamRetrievalURL: 'https://api.put.io/v2/files/list?parent_id=',
    retrieveMp4Source: '&breadcrumbs&sort&total&per_page&mp4_status=1&mp4_status_parent=1&video_metadata=1&video_metadata_parent=1&stream_url=1&stream_url_parent=1&mp4_stream_url=1&mp4_stream_url_parent=1&content_type&codecs_parent&media_info_parent',

    supabaseUrl: 'https://edyjscjgttlusdgrjysf.supabase.co',
    supabaseAnonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVkeWpzY2pndHRsdXNkZ3JqeXNmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTExODEwMDUsImV4cCI6MjAwNjc1NzAwNX0.YpV28DeCI_M8xpXnKBaeHtTwqnbOXpFX6GKmFjIFEWg',
}

export default requests;