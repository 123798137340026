import React, { useState, useEffect} from 'react';
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';
import InfoPane from '../../components/InfoPane/InfoPane';
import axios from '../../components/Axios/axios';
import requests from '../../components/Requests/requests';
import Row from '../../components/Row/Row';
import Spinner from '../../components/Loader/./Spinner';
import MessageManager from '../../components/MessageManager/MessageManager';

function Info() {
    let { id } = useParams();
    const [movie, setMovie] = useState([]);
    const [error, setError] = useState(false);
    const [suggestedMedia, setSuggestedMedia] = useState([]);
    const [openLoader, setOpenLoader] = React.useState(false);
    const handleOpenLoader = () => setOpenLoader(true);
    const handleCloseLoader = () => setOpenLoader(false);

    let isMovie = true;
    let fetchUrl, fetchSuggestedUrl;

    if (window.location.pathname.includes('show')) {
        isMovie = false;
    }

    useEffect(() => {
        handleOpenLoader();
        async function fetchData() {
            //Load movie/show data
            fetchUrl = requests.fetchIndividualMovieById + id;
            if (isMovie == false) {
                fetchUrl = requests.fetchIndividualShowById + id;
            }
            const request = await axios.get(fetchUrl)
            .then(request => {
                setMovie(request.data);
                fetchSuggestedUrl = requests.fetchMovies + '1?genre=' + encodeURIComponent(request.data.genres[0]) + '&order=-1&keywords=';
                if (isMovie == false) {
                    fetchSuggestedUrl = requests.fetchShows + '1?genre=' + encodeURIComponent(request.data.genres[0]) + '&order=-1&keywords=';
                }
                return axios.get(fetchSuggestedUrl)
                .then(suggestedRequest => {
                    setSuggestedMedia(suggestedRequest.data.slice(0, 6));
                })
            })
            .catch(function (error) {
                setError('Sorry, we were unable to load this media. Please try changing your API settings.');
            });
            handleCloseLoader();
            return request;
        }
        fetchData();
    }, [id]);
    if (error) {
        return (
            <motion.div 
                className="show-container"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
             <MessageManager showMessage={true} message={error} type={'error'}>
                {error}
             </MessageManager>
            </motion.div>   
        )    
    } else {
        return (
            <motion.div 
                className="info-container"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <Spinner open={openLoader}  />
                <div className='banner-background'></div>
                <div className="inner-content-body">
                    {movie._id &&
                        <InfoPane movie={movie}/>
                    }
                    <Row title={'You might also like..'} data={suggestedMedia} isLargeRow />
                </div>
            </motion.div>
        )
    }
}

export default Info;