import React, { useState, useEffect, useCallback  } from 'react';
import Skeleton from '@mui/material/Skeleton';

import './Banner.css';

function LoadingBanner({ data, showBanner }) {
        return (
            <>
            <header className={'banner'}>
                <div className="banner__contents">
                    <div className="banner__contents__container">
                        <ul>
                            <li>
                            <Skeleton sx={{ bgcolor: 'rgb(70 70 70)', fontSize:'1em', width:'40px' }} variant="text"  />
                            </li>
                            <li>
                            <Skeleton sx={{ bgcolor: 'rgb(70 70 70)', fontSize:'1em', width:'100px' }} variant="text"  />
                            </li>
                            <li>
                            <Skeleton sx={{ bgcolor: 'rgb(70 70 70)', fontSize:'1em', width:'40px' }} variant="text"  />
                            </li>
                        </ul>
                        
                        <Skeleton sx={{ bgcolor: 'rgb(70 70 70)', fontSize:'3em', width:'100%', paddingTop:'20px' }} variant="text"  />
                        <div className='banner__description'>
                        <Skeleton sx={{ bgcolor: 'rgb(70 70 70)'}} variant="rectangular" height={118} />
                        <Skeleton sx={{ bgcolor: 'rgb(70 70 70)', fontSize:'4.5em', width:'166px' }} variant="text"  />
                        </div>
                    </div>
                </div>    
            </header>
            </>
        )
}

export default LoadingBanner