import React from 'react';

const RuntimeRender = ({minutes}) => {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? '' + h : h; 
    m = m < 10 ? '0' + m : m; 
    return (h + 'hrs ' + m + 'mins');
};

export default RuntimeRender;