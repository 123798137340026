import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './TrailerModal.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'transparent',
    boxShadow: 24,
};

const TrailerModal = ({open, handleClose, trailer}) => {
if (trailer == undefined) {
    return ('');
}
const trailerId = trailer.split('=').pop();
  return (
        <Modal
            open={open}
            onClose={handleClose}
        >
        <Box sx={style} className="modal-container">
            <div className="video-container">
                <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + trailerId + "?autoplay=1"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            </div>
        </Box>
    </Modal>
  );
};

export default TrailerModal;