import React, { useState, useEffect, useCallback  } from 'react';
import {Helmet} from 'react-helmet';
import { NavLink } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import TrailerModal from '../../components/TrailerModal/TrailerModal';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import RuntimeRenderer from '../../components/RuntimeRenderer/RuntimeRenderer';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';

import './Banner.css';

const MenuItem = ({index, children }) => {
    const onEnterPress = useCallback(() => {
      ref.current.children[0].click()
    }, []);
    const onFocus = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: 'center'
            }); 
        }
    }, []);
    const { ref, focused } = useFocusable({
      onEnterPress,
      onFocus,
      focusKey:'BANNER' + index
    });
    return (
        <div ref={ref} className={focused ? 'banner-item focused' : 'banner-item'}>
          {children}
        </div>
    );
}

function Banner({ data, movieNo, showBanner }) {
    const [movie, setMovie] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { ref, setFocus, focusKey } = useFocusable({ focusKey: 'BANNER'});
    
    const seasonRenderer = (seasons) => {
        if (seasons == 1) {
            return seasons + ' season';
        } 
        return seasons + ' seasons';
    }

    useEffect(() => {
        setMovie(data[movieNo]);
        setFocus('BANNER1');
        console.log(data[movieNo])
      }, [data, setFocus]);
    if (data !== undefined && data.length > 0 && typeof data == 'object' && showBanner == true) {
        return (
            <>
            {movie?.trailer != undefined  &&
                <TrailerModal open={open} handleClose={handleClose} trailer={movie?.trailer} />
            }
            <header className={'banner'} style={{backgroundImage: `url(${movie?.images?.fanart.replace('w500', 'w1920_and_h800_multi_faces').replace("http", "https")}`}}>
            {movie?.trailer != undefined &&
                <div className="banner-trailer-container">
                    <iframe src={movie?.trailer.replace('/watch?v=', '/embed/').replace("http", "https") + '?autoplay=1&controls=0&mute=1&loop=1'} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
            }
                <div className="banner__contents">
                    <div className="banner__contents__container">
                        <h1 className="banner__title">{movie?.title}</h1>
                        <ul>
                            <li>
                                <span className="row__poster__year">{movie?.year}</span>
                            </li>
                            {movie?.runtime > 0 &&
                                <li>
                                    <span className="banner__length"><RuntimeRenderer minutes={movie?.runtime}/></span>    
                                </li>
                            }
                            {movie?.num_seasons > 0 &&
                                <li><span className="row__poster__length">{seasonRenderer(movie.num_seasons)}</span></li>
                            }
                            <li>
                                <div className="banner__rating">
                                <Rating name="read-only" value={(movie?.rating?.percentage/10)/2} readOnly />
                                </div>
                            </li>
                            {movie?.torrents?.en &&
                                <>
                                    {movie?.torrents?.en['2160p'] != undefined &&
                                        <li>
                                            <span className="label">UHD</span>
                                        </li>
                                    }
                                </>
                            }
                        </ul>
                        <span className="banner__description">{movie?.synopsis}</span>
                        <FocusContext.Provider value={focusKey}>
                            <div className="banner_contents__buttons" ref={ref}>
                                <MenuItem key={1} index={1}>
                                    {!showBanner &&
                                        <NavLink className="button main" to={`/movie/`+movie?._id}><PlayArrowIcon/> Watch Now</NavLink>
                                    }
                                    {showBanner &&
                                        <NavLink className="button main" to={`/show/`+movie?._id}><PlayArrowIcon/> Watch Now</NavLink>
                                    }
                                </MenuItem>
                                
                                    {movie?.trailer != undefined  &&
                                        <MenuItem key={2} index={2}>
                                            <div className="button secondary" onClick={handleOpen}><SlideshowIcon/> Trailer</div>
                                        </MenuItem>
                                    }
                                
                            </div>
                        </FocusContext.Provider>
                    </div>
                </div>    
            </header>
            </>
        )
    } else {
        return (
            <>
            </>
        );
    }
}

export default Banner