export const WatchList = (movie, action) => {
    let watchlist = {};
    if (action === "remove") {
        if (localStorage.getItem("watchList").includes(movie._id)) {
            watchlist = JSON.parse(localStorage.getItem("watchList"));
            delete watchlist[movie._id];
            localStorage.setItem("watchList", JSON.stringify(watchlist));
            return;
        }
    }
    //Watch list doesn't exist yet so add first movie and return
    if (localStorage.getItem("watchList") === null) {
        watchlist = {};
        watchlist[movie._id] = movie;
        localStorage.setItem("watchList", JSON.stringify(watchlist));
        window.dispatchEvent(new Event("storage")); //This is the important part
        return;
    }
    //Movie found in watchlist so nothing to do
    if (localStorage.getItem("watchList").includes(movie._id)) {
        return;
    }
    //Watchlist is not null but this film doesn't exist so add it
    watchlist = JSON.parse(localStorage.getItem("watchList"));
    watchlist[movie._id] = movie;
    localStorage.setItem("watchList", JSON.stringify(watchlist));
    window.dispatchEvent(new Event("storage")); //This is the important part
    return;
};