import React, { useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './Row.css';
import defaultImage from '../../img/default.jpg';
import RatingCircle from '../../components/RatingCircle/RatingCircle';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';

const MovieItem = ({ children }) => {
    const onEnterPress = useCallback(() => {
        ref.current.children[0].click()
    }, []);

    const onFocus = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: 'center'
            }); 
        }
    }, []);
    
    const { ref, focused } = useFocusable({
      onEnterPress,
      onFocus
    });
    return (
        <div ref={ref} className={focused ? 'banner-item focused' : 'banner-item'}>
          {children}
        </div>
      );
}

function Row({ title, data, isLargeRow }) {
    let navigate = useNavigate(); 
    const { ref, focusKey } = useFocusable();
    const handleClick = (movie) => {
        let path;
        path = `/movie/`+movie._id; 
        if (movie.num_seasons > 0) {
            path = `/show/`+movie._id;
        }  
        navigate(path);
    }

    if (data != undefined && data.length > 0 && typeof data == 'object') {
        return (
            <FocusContext.Provider value={focusKey}>
                <div className={`row ${isLargeRow && "row__posterLarge"}`} ref={ref}>
                    <h2>{title}</h2>
                    <div className="row__posters">
                        {data?.map((movie, index) => (
                            <MovieItem key={index}>
                                <div className={`row__poster ${isLargeRow && "row__posterLarge"}`} key={movie._id} onClick={() => handleClick(movie)}>
                                    <div className="row__poster__image">
                                        {movie?.images?.fanart != '' &&
                                            <img
                                            key={movie._id}
                                            src={isLargeRow ? movie.images.poster.replace("http", "https") : movie.images.fanart.replace("http", "https")  }
                                            alt={movie.title} />
                                        }
                                        {movie?.images?.fanart == '' &&
                                            <div className="row__poster__fallback">
                                                <p>{movie.title} </p>
                                            </div>
                                        }
                                        {movie?.torrents?.en != undefined &&
                                            <div>
                                                {movie?.torrents?.en['2160p'] != undefined &&
                                                    <span className="label">UHD</span>
                                                }
                                            </div>
                                        }
                                    {/*<div className="row__poster__rating">
                                        <RatingCircle rating={movie?.rating?.percentage} />
                                    </div>*/}
                                    </div>
                                </div>
                            </MovieItem>
                        ))}
                    </div>
                </div>
            </FocusContext.Provider>
        )
    } else {
        return (
            <>
            </>
        );
    }
}

export default Row