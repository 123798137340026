import React, { useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CategoryStack from '../../components/CategoryStack/CategoryStack';
import SettingsIcon from '@mui/icons-material/Settings';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import './HeaderNavigation.css';

const HeaderNavigationItem = ({ children, showTvStack }) => {
    const onEnterRelease = useCallback(() => {
        ref.current[1].select();
    }, []);
    const onFocus = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: 'center'
            }); 
        }
    }, []);
    const { ref, focused } = useFocusable({
      onEnterRelease,
      onFocus,
      focusKey:'SEARCHFORM'
    });
    var searchUrl = (showTvStack == true || window.location.pathname == '/search/shows') ? 'shows' :'movies';
    return (
        <form action={"/search/" + searchUrl} ref={ref} className={focused ? 'headerNavigation__search__form focused' : 'headerNavigation__search__form'}>
            {children}
        </form>
    )
}

function Navigation({showMovieStack, showTvStack, showSearchStack}) {
    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            event.target.form.submit();
        }
    }
    const { ref, focusKey } = useFocusable({ focusKey: 'SEARCH' });
    useEffect(() => {
    }, []);

    return (
        <>
        <div className="headerNavigation">
            <div className="headerNavigation__search">
                <HeaderNavigationItem key={1} showTvStack={showTvStack}>
                    <IconButton>
                        {<SearchIcon className="nav__search__search__icon"/>}
                    </IconButton>
                    <input type="text" name="query" placeholder="Search" id="searchForm" onKeyUp={handleKeyUp}/>
                </HeaderNavigationItem>
                {(showMovieStack || showTvStack || showSearchStack) &&
                    <CategoryStack showMovieStack={showMovieStack} showTvStack={showTvStack} showSearchStack={showSearchStack}/>      
                }
            </div>
        </div>
        </>
    )
}

export default Navigation