import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Pagination.css';

function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function Pagination(pageNumber) {
    pageNumber = parseInt(pageNumber.pageNumber);
    let url = window.location.pathname.replace("/" + pageNumber, "/");
    if (!endsWith(url, "/")) {
        url = window.location.pathname + "/";
    }
    if (window.location.pathname == "/") {
        url = "/movies/trending/"
    }
    return (
        <div className="Pagination__container">
            { pageNumber > 1 && 
                <NavLink className="button main" to={url + (pageNumber - 1)}>Previous Page</NavLink>
            }
            <NavLink className="button main" to={url + (pageNumber + 1)}>Next Page</NavLink>
        </div>
    )
}

export default Pagination