import React, { useState, useEffect} from 'react';
import { motion } from "framer-motion";
import { useSearchParams  } from 'react-router-dom';
import Row from '../../components/Row/Row';
import axios from '../../components/Axios/axios';
import requests from '../../components/Requests/requests';
import HeaderNavigation from '../../components/HeaderNavigation/HeaderNavigation';
import LoadingRow from '../../components/Row/LoadingRow';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import './Search.css';

function Search() {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("query");
    const [apiMovieResponse, setApiMovieResponse] = useState([]);
    const [apiShowResponse, setApiShowResponse] = useState([])
    const [openLoader, setOpenLoader] = React.useState(false);
    const handleOpenLoader = () => setOpenLoader(true);
    const handleCloseLoader = () => setOpenLoader(false);
    const { ref, setFocus, focusKey } = useFocusable();

    useEffect(() => {
        handleOpenLoader();
        var activeStack = (window.location.pathname == '/search/shows') ? 'CATEGORYSTACK2' : 'CATEGORYSTACK1';
        async function fetchMovieData() {
            const movieRequest = await axios.get(requests.fetchSearch + query);
            setApiMovieResponse(movieRequest.data);
            const showRequest = await axios.get(requests.fetchShowSearch + query);
            setApiShowResponse(showRequest.data);
            handleCloseLoader();
            setFocus(activeStack);
            return movieRequest;
        }
        fetchMovieData();
    }, [query]);

    return (
        <motion.div 
            className="search-container inner-content-body"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <HeaderNavigation showMovieStack={false} showTvStack={false} showSearchStack={true} />
            {openLoader && 
                <LoadingRow/>
            }
            {window.location.pathname == '/search/movies' &&
                <Row title={`Movie search results for "${query}"`} data={apiMovieResponse} isLargeRow />
            }
            {window.location.pathname == '/search/shows' &&
                <Row title={`TV Show search results for "${query}"`} data={apiShowResponse} isLargeRow />
            }
        </motion.div>
    )
}

export default Search;