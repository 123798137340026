import React, { useEffect } from 'react';
import Row from '../../components/Row/Row';
import HeaderNavigation from '../../components/HeaderNavigation/HeaderNavigation';
import { motion } from "framer-motion";
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';

function Watchlist() {
    let watchList = [];
    let watchHistory = [];
    const { ref, setFocus, focusKey } = useFocusable();

    for (const property in JSON.parse(localStorage.getItem('watchList'))) {
        watchList.push(JSON.parse(localStorage.getItem('watchList'))[property]);
    }
    for (const property in JSON.parse(localStorage.getItem('watchHistory'))) {
        watchHistory.push(JSON.parse(localStorage.getItem('watchHistory'))[property]);
    }
    useEffect(() => {
        setFocus('SEARCHFORM');
    }, [setFocus]);

    return (
        <motion.div 
            className="watchlist-container inner-content-body"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <HeaderNavigation showMovieStack={false} showTvStack={false} showSearchStack={false} />
            <Row title="CONTINUE WATCHING" data={watchHistory} isLargeRow />
            <Row title="Watchlist" data={watchList} isLargeRow />
        </motion.div>
    )
}

export default Watchlist;
