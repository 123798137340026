import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';

import './MessageManager.css';

function MessageManager({showMessage, message, type, children, setShowMessage}) {
    const vertical = 'top';
    const horizontal = 'center';
    const [displayMessage, setDisplayMessage] = useState(showMessage);
    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            showMessage = false;
            setDisplayMessage(false);
            setShowMessage(false);
            //return;
        }
        showMessage = false;
        setDisplayMessage(false);
        setShowMessage(false);
    };

    useEffect(() => {
        setDisplayMessage(showMessage);
    }, [children, showMessage]);

    if (!displayMessage) {
        return;
    }

    return (
        <Snackbar open={displayMessage} key={new Date().getTime()} onClose={handleErrorClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert onClose={handleErrorClose} severity={type} sx={{ width: '100%' }} className={type} iconMapping={{
                warning: <CircularProgress />,
                error: <ErrorOutlineIcon/>
            }}>
                <>
                    {children}
                </>
            </Alert>
        </Snackbar>
    )
}

export default MessageManager