import React from 'react';
import { useState } from "react";
import { motion } from "framer-motion";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import "./Reportbug.css"

function Reportbug() {
    const [bugreport, setBugreport] = useState("");
    const [message, setMessage] = useState([]);

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let res = await fetch("https://api.ayo.watch/bug/index.php", {
            method: "POST",
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                bugreport: bugreport,
            }),
          });
          let resJson = await res.json();
          if (res.status === 200) {
            setMessage({"type": "success", "message": "Thank you for your report."});
          } else {
            setMessage({"type": "error", "message": "An error occurred whilst sending your message."});
          }
        } catch (err) {
          setMessage({"type": "error", "message": "An error occurred whilst sending your message."});
        }
    };

    return (
                <motion.div 
                    className="settings__form__container inner-content-body"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                  <h1>Report a Bug</h1>
                  <p>Use the form below to submit a bug report. Please try and be as descriptive as you can in the message details.</p>
                  <div className="settings__form">
                      <h2>Compose message</h2>
                      <Divider variant="middle" />
                      <div className={"message " + message.type}>{message.type ? <p>{message.message}</p> : null}</div>
                      <form onSubmit={handleSubmit}>
                          <FormControl>
                              <TextField
                                  id="outlined-multiline-static"
                                  label="Message"
                                  multiline
                                  rows={4}
                                  value={bugreport}
                                  onChange={(e) => setBugreport(e.target.value)}
                              />
                              <button type="submit" className="button main">Send message</button>
                          </FormControl>
                      </form>
                  </div>
              </motion.div>
    )
}

export default Reportbug;