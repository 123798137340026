import React from 'react';
import './Settings.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useLocalStorage } from "../../components/LocalStorage/UseLocalStorage";
import { motion } from "framer-motion";


function Settings() {
    const [autoQuality, setAutoQuality] = useLocalStorage("autoQuality", "");
    const [defaultQuality, setDefaultQuality] = useLocalStorage("defaultQuality", "");
    const [fileFormat, setFileFormat] = useLocalStorage("fileFormat", "");
    const [showMagnet, setMagnetVisible] = useLocalStorage("magnetVisible", false);
    const [downloadButton, setDownloadButton] = useLocalStorage("downloadButton", false);
    const [autoPlay, setAutoPlay] = useLocalStorage("autoPlay", true);
    const [startFullscreen, setStartFullscreen] = useLocalStorage("startFullscreen", true);
    const [apiServer, setApiServer] = useLocalStorage("apiServer", "https://corsproxy.io/?https://jfper.link");

    const handleClear = (storage) => {
        if (storage != 'cache') {
            localStorage.removeItem(storage);
        } else {
            localStorage.clear();
        }
    };
  
    return (
                <motion.div 
                    className="settings__form__container inner-content-body"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <h1>Settings</h1>
                    <p>All settings are automatically saved when changed.</p>
                    <div className="settings__form">
                    <h2>App Settings</h2>
                    <Divider variant="middle" />
                            <FormControl>
                                <FormLabel id="settings__form__label__quality">File format</FormLabel>
                                <FormLabel id="settings__form__label__quality">MKV files are uncompressed and better quality however they require a codec to work. If you experience audio issues when playing media then try switching to MP4.</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    onChange={(e) => setFileFormat(e.target.value)}
                                    value={fileFormat}
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="" control={<Radio />} label="MKV" />
                                    <FormControlLabel value="MP4" control={<Radio />} label="MP4" />
                                </RadioGroup>
                            </FormControl>

                    <h2>Playback Settings</h2>
                    <Divider variant="middle" />    
                        <FormGroup>
                            <FormLabel id="settings__form__label__quality">Automatically play next episode when streaming TV shows</FormLabel>
                            <FormControlLabel control={<Switch defaultChecked />} checked={autoPlay} onChange={(e) => setAutoPlay(e.target.checked)} />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel id="settings__form__label__quality">Always start playing in fullscreen</FormLabel>
                            <FormControlLabel control={<Switch defaultChecked />} checked={startFullscreen} onChange={(e) => setStartFullscreen(e.target.checked)} />
                        </FormGroup>

                    <h2>API Settings</h2>
                    <Divider variant="middle" />  
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">API Server</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            onChange={(e) => setApiServer(e.target.value)}
                            value={apiServer}
                        >
                            <MenuItem value={"https://corsproxy.io/?https://jfper.link"}>Use 3rd Party Servers</MenuItem>
                            <MenuItem value={"https://api.ayo.watch"}>Use AYO Servers</MenuItem>
                        </Select>
                    </FormControl>

                        <h2>Clear Data</h2>
                        <Divider variant="middle" />
                        <FormControl>
                            <FormLabel id="settings__form__label__watchlist">Clear watchlist data from browser</FormLabel>
                            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleClear("watchList")}>
                                Delete watchlist
                            </Button>
                        </FormControl>
                        
                        <FormControl>
                            <FormLabel id="settings__form__label__history">Clear watch history from browser</FormLabel>
                            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleClear("watchHistory")}>
                                Delete watch history
                            </Button>
                        </FormControl>

                        <FormControl>
                            <FormLabel id="settings__form__label__history">Clear all local data</FormLabel>
                            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleClear("cache")}>
                                Delete data
                            </Button>
                        </FormControl>

                    </div>
                </motion.div>
    )
}

export default Settings;