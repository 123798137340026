import React, { useState, useEffect  } from 'react';
import axios from '../Axios/axios';
import requests from '../../components/Requests/requests';
import MessageManager from '../../components/MessageManager/MessageManager';
import './Downloader.css';

function Downloader({ id  }) {
    const [movie, setMovie] = useState('');
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        async function fetchData() {
            const request = axios.get(requests.baseStreamRetrievalURL + id + requests.retrieveMp4Source + requests.putioApiKey.replace("?","&"))
              .then(function (response) {
                    if (response.data.parent.hasOwnProperty('mp4_stream_url')) {
                        setMovie(response.data.parent); 
                        window.location.assign('https://api.put.io/v2/files/' + response.data.parent.id  + '/download' + requests.putioApiKey);   
                    } else {
                        setMovie(response.data.parent);
                        window.location.assign('https://api.put.io/v2/files/' + response.data.parent.id  + '/download' + requests.putioApiKey);
                    }
              })
              .catch(function (error) {
                console.log(error);
              });
            return request;
        }
        fetchData();
    }, [id]);

    return (
        <>
            <p><strong>Your download has started.</strong></p>
            <p className='file-download'>Click to manually download: <a href={'https://api.put.io/v2/files/' + movie.id  + '/download' + requests.putioApiKey}>Download File</a></p>
        </>
    )
}

export default Downloader