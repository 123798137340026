import React, {} from 'react';
import Skeleton from '@mui/material/Skeleton';
import './Row.css';


function LoadingRow({ title, data, isLargeRow }) {
    const movieCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        return (
                <div className={`row ${isLargeRow && "row__posterLarge"}`}>
                    <h2>{title}</h2>
                    <div className="row__posters">
                        {movieCount?.map((movie, index) => (
                                <div className={`row__poster ${isLargeRow && "row__posterLarge"}`} key={movie}>
                                    <div className="row__poster__image">
                                    <Skeleton sx={{ bgcolor: 'rgb(70 70 70)'}} variant="rectangular" height={275} />
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
        )
}

export default LoadingRow